import axios from "axios";

//const EMPLOYEE_API_BASE_URL = "http://localhost:8080/api/v1/employees";
//const EMPLOYEE_API_BASE_URL =  "http://localhost/pay-unify.com/puinternal/api/employees";
const EMPLOYEE_API_BASE_URL =  "http://52.66.212.234/puinternal/api/employees";
const headers = {
  "Content-type": "application/x-www-form-urlencoded",
  Accept: "application/json",
};

class EmployeeService {
  getEmployees() {
    return axios.get(EMPLOYEE_API_BASE_URL, { headers });
  }

  createEmployee(employee) {
    return axios.post(EMPLOYEE_API_BASE_URL, employee, { headers });
  }

  getEmployeeById(employeeId) {
    return axios.get(EMPLOYEE_API_BASE_URL + "/index/" + employeeId, { headers });
  }

  updateEmployee(employee, employeeId) {
   // return axios.put(EMPLOYEE_API_BASE_URL + "/index/" + employeeId, employee);
    return axios.post(EMPLOYEE_API_BASE_URL + "/index/" + employeeId, employee);
  }

  deleteEmployee(employeeId) {
    return axios.delete(EMPLOYEE_API_BASE_URL + "/delete/" + employeeId);
  }
}

export default new EmployeeService();
